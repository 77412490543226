<template>
    <div>
        <div class="header">
            <span @click="gouot"></span>
            <span>信息录入</span>
            <span @click="islogout">退出</span>
        </div>
        <div class="content">
            <div class="content-p">
                <p class="content-title">{{ shopName }}</p>
                <p>{{ userName }} （{{ realName }}）</p>
            </div>
            <div class="btn-bom">

                <van-button class="content-btn" type="info" @click="$router.push('/FullCar')" size="large">整车照</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/CarInfo')" size="large">车辆信息</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/OwnerInfo')"
                    size="large">车主信息</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/DestroyedLine')"
                    size="large">毁形信息</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/Household')"
                    size="large">销户凭证</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/AllInfo')"
                    size="large">全量信息录入</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/CarQuery')"
                    size="large">车辆查询</van-button>
                <van-button class="content-btn" type="info" @click="$router.push('/GetDefineNum')"
                    size="large">生成自定义进场编码</van-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
    computed: {
        shopName () {
            return window.localStorage.getItem('shopName') || ''
        },
        userName () {
            return window.localStorage.getItem('userName') || ''
        },
        realName () {
            return window.localStorage.getItem('realName') || ''
        },
    },
    methods: {
        gouot () {
            this.$router.push('/guide')
        },

    },
}
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;

    span {
        display: inline-block;
    }

    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}

.content {
    padding-top: 50px;

    .content-p {
        padding: 10px;
        padding-left: 20px;
        margin-top: 10px;
        // background-color: #16cffb;
        background: -webkit-gradient(linear, 0 0, 0 100%, from(#16cffb), to(#99eaff));
        // background: -moz-gradient(linear,0 0,0 100%,from(#16cffb),to(#99eaff));     
        // background:-webkit-gradient(linear,left top,left bottom,from(#16cffb),to(#99eaff));
    }

    .content-title {
        font-weight: bold;
    }

    .content-btn {
        margin: 10px 0;
    }

    .btn-bom {
        padding: 0 10px;
        margin-top: 50px;
    }
}
</style>